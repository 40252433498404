import React from "react";
import "./RadioButton.css";

function RadioButton(props: { selected: boolean; onClick: () => void }) {
  return (
    <button
      className={props.selected ? "radio-button-selected" : "radio-button"}
      onClick={() => props.onClick()}
    >
      {props.selected && "✔"}
    </button>
  );
}

export default RadioButton;
