import React from "react";
import "./LevelTag.css";

function LevelTag(props: { text: string; level: number }) {
  const getBackgroundColor = (lev: number) => {
    switch (lev) {
      case 1:
        return "#EE4F4F";
      case 2:
        return "#FFAB00";
      case 3:
        return "#3EAF3F";
      default:
        return "black";
    }
  };

  return (
    <div
      className="level-tag"
      style={{
        backgroundColor: getBackgroundColor(props.level),
      }}
    >
      {props.text}
    </div>
  );
}

export default LevelTag;
