import React, { useState } from "react";
import "./Button.css";
import helpButton from "../Assets/help_button.png";
import helpButtonHover from "../Assets/help_button_hover.png";

import { useGlobalContext } from "../GlobalContext";

// Modified the props passed. Can also have optional style!
function NextButton(props: {
  buttonAction: () => void;
  disable?: boolean;
  text?: string;
  style?: React.CSSProperties;
}) {
  const { isSpanish } = useGlobalContext();
  return (
    <button
      className={props.disable ? "next-button-disabled" : "next-button"}
      style={props.style} // if style is provided as a prop, className is overriden!
      disabled={props.disable}
      onClick={() => props.buttonAction()}
    >
      <span style={{ marginRight: "0%" }}>
        {props.text || !isSpanish ? "Next" : "Siguiente"}
      </span>{" "}
      <span style={{ fontSize: "2em", bottom: "4.5pt" }}>&#8594;</span>
    </button>
  );
}

function BackButton(props: {
  buttonAction: () => void;
  style?: React.CSSProperties;
}) {
  const { isSpanish } = useGlobalContext();

  return (
    <button
      className="next-button"
      style={props.style} // if style is provided as a prop, className is overriden!
      onClick={() => props.buttonAction()}
    >
      <span style={{ fontSize: "2em", marginRight: "2%", bottom: "4.5pt" }}>
        &larr;
      </span>
      <span>{!isSpanish ? "Back" : "Atrás"}</span>
    </button>
  );
}

function RetakeQuizButton(props: { buttonAction: () => void }) {
  return (
    <button className="retake-quiz-button" onClick={() => props.buttonAction()}>
      Retake Quiz
    </button>
  );
}

function RestartButton(props: { buttonAction: () => void }) {
  return (
    <button className="retake-quiz-button" onClick={() => props.buttonAction()}>
      Restart
    </button>
  );
}

function HelpButton(props: { buttonAction: () => void }) {
  const [isHovered, setIsHovered] = useState(false);
  const { isSpanish } = useGlobalContext();

  return (
    <button
      className="help-button"
      onClick={() => props.buttonAction()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={isHovered ? helpButtonHover : helpButton} alt="Help Button" />
      <p>{!isSpanish ? "help" : "ayuda"}</p>
    </button>
  );
}

function CloseButton(props: {
  buttonAction: () => void;
  style?: React.CSSProperties;
}) {
  const { isSpanish } = useGlobalContext();
  return (
    <button
      className="close-button"
      onClick={() => props.buttonAction()}
      style={props.style}
    >
      {!isSpanish ? <> Close &#x2715;</> : <>Cerrar</>}
    </button>
  );
}

function SpanishButton(props: { style?: React.CSSProperties }) {
  const { isSpanish, setIsSpanish } = useGlobalContext();
  return (
    <button
      className="spanish-button"
      onClick={() => {
        setIsSpanish((prev) => !prev);
      }}
      style={props.style}
    >
      <span style={{ fontSize: "2em", bottom: "2.5pt", marginRight: "10%" }}>
        &harr;
      </span>
      {!isSpanish ? <span>Español</span> : <span>English</span>}
    </button>
  );
}

export {
  NextButton,
  BackButton,
  RetakeQuizButton,
  RestartButton,
  HelpButton,
  CloseButton,
  SpanishButton,
};
