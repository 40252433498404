import "./Quiz.css";
import { NextButton } from "../Components/Button";
import { useGlobalContext } from "../GlobalContext";
import React from "react";

function QuizResults(props: {
  pass: () => void;
  fail: () => void;
  quizScore: any;
  retake: boolean;
}) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="quiz-results">
      <h3>✅</h3>
      {!isSpanish ? (
        <>
          <h3>Review complete!</h3>
          <h3>Click on next to start the survey.</h3>
          <br />
        </>
      ) : (
        <>
          <h3>¡Revisión completa!</h3>
          <h3>Presione “Siguiente” para empezar la encuesta.</h3>
          <br />
        </>
      )}

      <NextButton
        buttonAction={props.pass}
        style={{ fontSize: "20pt", paddingBottom: "1%", lineHeight: "0" }}
      />
    </div>
  );

  // Old code from when survey failing was a thing
  // if (props.quizScore < 5) {
  //     if (props.retake) {
  //         return (
  //             <div className='quiz-results'>
  //                 <h3>❎</h3>
  //                 <h3>You scored {props.quizScore}/5.</h3>
  //                 <h3>You need to score 5/5 to move on to the survey.</h3>
  //                 <br />
  //                 <RestartButton buttonAction={props.fail} />
  //             </div>
  //         );
  //     }
  //     else {
  //         return (
  //             <div className='quiz-results'>
  //                 <h3>❎</h3>
  //                 <h3>You scored {props.quizScore}/5.</h3>
  //                 <h3>You need to score 5/5 to move on to the survey.</h3>
  //                 <br />
  //                 <RetakeQuizButton buttonAction={props.fail} />
  //             </div>
  //         );
  //     }
  // }
  // else {
  //     return (
  //         <div className='quiz-results'>
  //             <h3>✅</h3>
  //             <h3>Review complete!</h3>
  //             <h3>Click on next to start the survey.</h3>
  //             <br />
  //             <NextButton buttonAction={props.pass} />
  //         </div>
  //     )
  // }
}

export default QuizResults;
