import {
  BackButton,
  CloseButton,
  NextButton,
  SpanishButton,
} from "../Components/Button";
import LevelTag from "../Components/LevelTag";
import thermal_hot from "../Assets/thermal_hot.jpg";
import thermal_warm from "../Assets/thermal_moderate.jpg";
import thermal_neutral from "../Assets/thermal_neutral.jpg";
import thermal_comfort from "../Assets/thermal_comfort.png";
import { CSSProperties } from "react";
import ProgressBar from "../Components/ProgressBar";
import { useGlobalContext } from "../GlobalContext";
import React from "react";

const buttonStyle = {
  padding: "10px 50px",
  fontSize: "20px",
  border: "none",
  borderRadius: "300px",
  fontWeight: "bold",
  color: "white",
  margin: "0 10px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2%",
};

function ThermalComfort(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {!isSpanish ? (
        <>
          <ProgressBar
            steps={[
              "Walk Time",
              "Sidewalk Condition",
              "Crime Safety",
              "Traffic Safety",
              "Thermal Comfort",
            ]}
            currentStep={5}
          />
        </>
      ) : (
        <>
          <ProgressBar
            steps={[
              "Tiempo de Caminata",
              "Condición de la Acera",
              "Seguridad en Relación al Crimen",
              "Seguridad Vial",
              "Comodidad Térmica",
            ]}
            currentStep={5}
          />
        </>
      )}

      <div className="content-container">
        <div className="text-content">
          {!isSpanish ? (
            <>
              <h1 className="emoji-header" style={{ marginBottom: "-5%" }}>
                🚶
              </h1>
              <h1>5. Thermal Comfort</h1>
              <p>
                Thermal comfort refers to the degree to which a person feels
                comfortable with the surrounding temperature.
              </p>
              <p>
                There are three levels for thermal comfort: hot, warm, and
                neutral.
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button style={{ ...buttonStyle, backgroundColor: "#ff4d4d" }}>
                  Hot
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#ffbf00" }}>
                  Warm
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#00b300" }}>
                  Neutral
                </button>
              </div>
            </>
          ) : (
            <>
              <h1 className="emoji-header" style={{ marginBottom: "-5%" }}>
                🚶
              </h1>
              <h1>5. Comodidad Térmica</h1>
              <p>
                La comodidad térmica: se refiere al grado en que una persona se
                siente cómoda con la temperatura del ambiente.
              </p>
              <p>Hay tres niveles de comodidad térmica:</p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button style={{ ...buttonStyle, backgroundColor: "#ff4d4d" }}>
                  Caliente
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#ffbf00" }}>
                  Cálida
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#00b300" }}>
                  Neutral
                </button>
              </div>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={thermal_comfort} alt="Sidewalk Condition" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function ThermalComfortHot(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div className="content-container">
        <div className="text-content">
          {!isSpanish ? (
            <>
              {" "}
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#ff4d4d",
                }}
              >
                Hot
              </button>
              <h1>Thermal Comfort</h1>
              <p>
                Individuals experience <strong>sweating</strong>,{" "}
                <strong>discomfort</strong>, and <strong>exhaustion</strong>.
              </p>
            </>
          ) : (
            <>
              {" "}
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#ff4d4d",
                }}
              >
                Caliente
              </button>
              <h1>Comodidad Térmica</h1>
              <p>
                Las personas experimentan{" "}
                <strong>sudoración, incomodidad, y agotamiento</strong>.
              </p>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={thermal_hot} alt="Bad thermal comfort" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function ThermalComfortWarm(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();
  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div className="content-container">
        <div className="text-content">
          {!isSpanish ? (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#ffbf00",
                }}
              >
                Warm
              </button>
              <h1>Thermal Comfort</h1>
              <p>
                Individuals experience <strong>mild sweating</strong> and{" "}
                <strong>discomfort</strong>.
              </p>
            </>
          ) : (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#ffbf00",
                }}
              >
                Cálida
              </button>
              <h1>Comodidad Térmica</h1>
              <p>
                Las personas experimentan{" "}
                <strong>sudoración leve y incomodidad</strong>.
              </p>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={thermal_warm} alt="Moderate thermal comfort" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function ThermalComfortNeutral(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div className="content-container">
        <div className="text-content">
          {!isSpanish ? (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#00b300",
                }}
              >
                Neutral
              </button>
              <h1>Thermal Comfort</h1>
              <p>
                Individuals feel <strong>comfortable</strong> and{" "}
                <strong>pleasant</strong> with the surrounding environment.
              </p>
            </>
          ) : (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#00b300",
                }}
              >
                Neutral
              </button>
              <h1>Comodidad Térmica</h1>
              <p>
                Las personas se sienten <strong>cómodas y agradables</strong>{" "}
                con el entorno que les rodea.
              </p>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={thermal_neutral} alt="Neutral thermal comfort" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function ThermalComfortTable() {
  const { isSpanish } = useGlobalContext();

  const columnStyle: CSSProperties = {
    width: "32%",
    margin: "10px",
    padding: "20px",
    boxSizing: "border-box",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f0f0f0",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1%",
        padding: "0 4%",
        boxSizing: "border-box",
        width: "100%",
      }}
    >
      {!isSpanish ? (
        <>
          {" "}
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Hot" level={1} />
            <p style={{ textAlign: "center" }}>
              Individuals experience <strong>sweating</strong>,{" "}
              <strong>discomfort</strong>, and <strong>exhaustion</strong>.
            </p>
            <img
              src={thermal_hot}
              alt="Hot thermal comfort"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Warm" level={2} />
            <p style={{ textAlign: "center" }}>
              Individuals experience <strong>mild sweating</strong> and{" "}
              <strong>discomfort</strong>.
            </p>
            <img
              src={thermal_warm}
              alt="Warm thermal comfort"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Neutral" level={3} />
            <p style={{ textAlign: "center" }}>
              Individuals feel <strong>comfortable</strong> and{" "}
              <strong>pleasant</strong>.
            </p>
            <img
              src={thermal_neutral}
              alt="Neutral thermal comfort"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Caliente" level={1} />
            <p style={{ textAlign: "center" }}>
              Las personas experimentan{" "}
              <strong>sudoración, incomodidad, y agotamiento</strong>.
            </p>
            <img
              src={thermal_hot}
              alt="Hot thermal comfort"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Cálida" level={2} />
            <p style={{ textAlign: "center" }}>
              Las personas experimentan{" "}
              <strong>sudoración leve y incomodidad</strong>.
            </p>
            <img
              src={thermal_warm}
              alt="Warm thermal comfort"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Neutral" level={3} />
            <p style={{ textAlign: "center" }}>
              Las personas se sienten <strong>cómodas y agradables</strong> con
              el entorno que les rodea.
            </p>
            <img
              src={thermal_neutral}
              alt="Neutral thermal comfort"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
        </>
      )}
    </div>
  );
}

function ThermalComfortSummary(props: {
  next?: any;
  back?: any;
  closeHandler?: any;
}) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {props.closeHandler && (
        <div className="top-left-button">
          <CloseButton buttonAction={props.closeHandler} />
        </div>
      )}

      <h2 style={{ alignSelf: "flex-start", marginLeft: "4%" }}>
        {!isSpanish ? <>Thermal Comfort</> : <>Comodidad Térmica</>}
      </h2>

      <ThermalComfortTable />
      {props.back && (
        <div className="bottom-left-button">
          <BackButton buttonAction={props.back} />
        </div>
      )}

      {props.next && (
        <div className="bottom-right-button">
          <NextButton buttonAction={props.next} />
        </div>
      )}
    </div>
  );
}

export default ThermalComfort;
export {
  ThermalComfortHot,
  ThermalComfortWarm,
  ThermalComfortNeutral,
  ThermalComfortSummary,
  ThermalComfortTable,
};
