import { useState } from "react";

import { NextButton, SpanishButton } from "../Components/Button";
import { SidewalkConditionTable } from "../Education/SidewalkCondition";
import "./Quiz.css";
import { useGlobalContext } from "../GlobalContext";
import React from "react";

function Quiz1(props: { next: any; incrementScore: any }) {
  const [selected, setSelected] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { isSpanish } = useGlobalContext();

  const correct: number = 2;

  const handleSubmit = () => {
    setSubmitted(true);
    if (selected === correct) {
      props.incrementScore();
    }
  };

  const handleNext = () => {
    props.next();
  };

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>
      {!submitted &&
        (!isSpanish ? (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>Question 1</h2>
              <p>
                Which sidewalk condition is considered better maintained and
                easier to walk?
              </p>
              <div className="quiz-options">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(1)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  <strong>Moderate</strong> sidewalk condition
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(2)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  <strong>Good</strong> sidewalk condition
                </label>
              </div>
              <div className="bottom-right-button">
                <NextButton
                  buttonAction={handleSubmit}
                  disable={selected === 0}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>Pregunta 1</h2>
              <p>
                ¿Cuál es la condición de la acera que se considera mejor
                mantenida y más fácil de caminar?
              </p>
              <div className="quiz-options">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(1)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  Condición de acera <strong>moderada</strong>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(2)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  Condición de <strong>acera buena</strong>
                </label>
              </div>
              <div className="bottom-right-button">
                <NextButton
                  buttonAction={handleSubmit}
                  disable={selected === 0}
                />
              </div>
            </div>
          </>
        ))}

      {submitted &&
        selected !== correct &&
        (!isSpanish ? (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>💡&nbsp;&nbsp;Nice try!</h2>
              <p>
                <strong>Good</strong> sidewalk condition is better maintained
                and easier to walk.
              </p>
              <br />
              <SidewalkConditionTable />
              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>💡&nbsp;&nbsp;¡Buen intento!</h2>
              <p>
                Se considera que una acera en buena condición está mejor
                mantenida y es más fácil de caminar.
              </p>
              <br />
              <SidewalkConditionTable />
              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ))}

      {submitted &&
        selected === correct &&
        (!isSpanish ? (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>✅&nbsp;&nbsp;Correct!</h2>
              <p>
                <strong>Good</strong> sidewalk condition is better maintained
                and easier to walk.
              </p>
              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>✅&nbsp;&nbsp;¡Correcto!</h2>
              <p>
                Se considera que una acera en buena condición está mejor
                mantenida y es más fácil de caminar.
              </p>
              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ))}
    </div>
  );
}

export default Quiz1;
