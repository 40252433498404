import { BackButton, NextButton, SpanishButton } from "../Components/Button";
import couple_walking from "../Assets/couple-walking.png";

import "./General.css";
import ProgressBar from "../Components/ProgressBar";
import { useGlobalContext } from "../GlobalContext";
import React from "react";

function WalkTime(props: { back: any; next: any }) {
  const { isSpanish } = useGlobalContext();
  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {!isSpanish ? (
        <>
          <ProgressBar
            steps={[
              "Walk Time",
              "Sidewalk Condition",
              "Crime Safety",
              "Traffic Safety",
              "Thermal Comfort",
            ]}
            currentStep={1}
          />
        </>
      ) : (
        <>
          <ProgressBar
            steps={[
              "Tiempo de Caminata",
              "Condición de la Acera",
              "Seguridad en Relación al Crimen",
              "Seguridad Vial",
              "Comodidad Térmica",
            ]}
            currentStep={1}
          />
        </>
      )}

      <div className="content-container">
        <div className="text-content">
          <h1 className="emoji-header">⏰</h1>
          {!isSpanish ? (
            <>
              <h1>1. Walk Time</h1>
              <p>
                Walk time refers to the duration of walking to reach the
                destination.
              </p>
            </>
          ) : (
            <>
              <h1>1. Tiempo de Caminata</h1>
              <p>
                El tiempo de caminata se refiere a la duración de la caminata
                para llegar al destino.
              </p>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={couple_walking} alt="Couple Walking" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

export default WalkTime;
